import type { Prisma } from '@prisma/client'

export const MAX_UNREAD_MESSAGES_COUNT = 9

export function matchesTableDataSelectFragment({
  applicationIdTokens,
  managedNctIds,
  managedSiteIds,
  trialProIdToken,
}: {
  applicationIdTokens: string[]
  managedNctIds: string[]
  managedSiteIds: number[]
  trialProIdToken: string
}) {
  return {
    appliedAt: true,
    clinicalTrial: {
      select: {
        activeContractWithSponsor: true,
        biologicalSex: true,
        biologicalSexBased: true,
        briefTitle: true,
        conditions: {
          select: {
            condition: {
              select: {
                colloquialTermStripped: true,
                name: true,
              },
            },
          },
        },
        gender: true,
        genderBased: true,
        healthyVolunteers: true,
        maximumAge: true,
        maximumBmi: true,
        minimumAge: true,
        minimumBmi: true,
        orgStudyIdInfo: true,
        parsedEligibilityCriteria: {
          select: {
            authoringTrialProId: true,
            deletedAt: true,
            id: true,
            isInclusion: true,
            isMajor: true,
            isPreScreenerEnabled: true,
            isVerificationAllowed: true,
            overrideAt: true,
            tags: true,
          },
        },
        timeline: true,
      },
    },
    completedAt: true,
    data: true,
    eligibilityMetadata: true,
    eligibilityResponses: {
      select: {
        answer: true,
        answerMetadata: true,
        eligibilityCriteria: {
          // We do not need to select "deletedAt" since we show previously
          // recorded responses to criteria if deleted since
          select: {
            criterion: true,
            id: true,
            isInclusion: true,
            isPreScreenerEnabled: true,
            isRequiredForQualification: true,
            maxQualifiable: true,
            minQualifiable: true,
            type: true,
          },
        },
        eligibilityCriteriaId: true,
        id: true,
      },
    },
    eligibilityScore: true,
    hasUniquePerPatientPhoneAlias: true,
    id: true,
    idToken: true,
    matchRank: {
      select: { rank: true },
    },
    medicalDocuments: {
      select: {
        archivedAt: true,
        documentType: true,
      },
    },
    nctId: true,
    nearestTrialLocation: {
      select: {
        facility: true,
        id: true,
        state: true,
      },
    },
    patientLastContactedAt: true,
    patientNavigatorAgentCalls: {
      include: {
        patientNavigatorAgentCallLog: true,
      },
      where: {
        patientNavigatorAgentCallLog: {
          serviceType: 'TrialApply',
        },
      },
    },
    patientPhoneAlias: true,
    relayThreadJoins: {
      select: {
        relayThread: {
          select: {
            emailRelaysToPatient: {
              orderBy: {
                createdAt: 'desc' as Prisma.SortOrder,
              },
              select: {
                createdAt: true,
              },
              take: 5,
            },
            emailRelaysToSiteContact: {
              orderBy: {
                createdAt: 'desc' as Prisma.SortOrder,
              },
              select: {
                createdAt: true,
              },
              take: 5,
            },
            patientEmailAlias: true,
            patientPhoneAlias: true,
            phoneRelays: {
              orderBy: {
                createdAt: 'desc' as Prisma.SortOrder,
              },
              select: {
                createdAt: true,
                recordingWebhook: true,
              },
              take: 5,
            },
          },
        },
      },
    },
    screeningVisitAvailabilities: true,
    selectedTrialLocation: {
      select: {
        facility: true,
        id: true,
        state: true,
      },
    },
    stage: true,
    stageHistories: {
      select: {
        createdAt: true,
        stage: true,
      },
    },
    substage: true,
    trialProFollowUps: {
      select: {
        followUpAt: true,
      },
      where: {
        trialPro: {
          idToken: trialProIdToken,
        },
      },
    },
    trialProOwnership: {
      select: {
        trialPro: {
          select: {
            name: true,
          },
        },
      },
    },
    user: {
      select: {
        conditionsJoin: {
          select: {
            condition: {
              select: {
                name: true,
              },
            },
          },
        },
        dateOfBirth: true,
        email: true,
        idToken: true,
        medicalDocuments: {
          select: {
            archivedAt: true,
            documentType: true,
          },
        },
        name: true,
        patientAddress: {
          select: {
            zipCode: {
              select: {
                timezone: true,
              },
            },
          },
        },
        patientProfilePhoto: {
          select: {
            externalImage: {
              select: {
                externalId: true,
              },
            },
          },
        },
        phoneNumbers: {
          select: { id: true, leadScore: true, phoneNumber: true },
        },
        trialApplications: {
          select: {
            appliedAt: true,
            clinicalTrial: {
              select: {
                activeContractWithSponsor: true,
                briefTitle: true,
                nctId: true,
                parsedEligibilityCriteria: true,
              },
            },
            eligibilityMetadata: true,
            id: true,
            idToken: true,
            nctId: true,
            nearestTrialLocation: {
              select: {
                facility: true,
                state: true,
              },
            },
            selectedTrialLocation: {
              select: {
                facility: true,
                state: true,
              },
            },
            stage: true,
            status: true,
          },
          where: {
            AND: [
              { idToken: { in: applicationIdTokens } },
              {
                OR: [
                  { selectedTrialLocationId: { in: managedSiteIds } },
                  {
                    AND: [
                      { selectedTrialLocationId: null },
                      { nearestTrialLocationId: { in: managedSiteIds } },
                    ],
                  },
                ],
              },
              { nctId: { in: managedNctIds } },
            ],
          },
        },
      },
    },
    userUnreadMessages: {
      select: {
        sentAt: true,
      },
      take: MAX_UNREAD_MESSAGES_COUNT,
      where: {
        userIdToken: trialProIdToken,
      },
    },
  }
}
