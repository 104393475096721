import Link from '@components/primitives/Link'
import { Text } from '@components/primitives/Text'
import { classMerge } from '@components/utilities/classMerge'
import type { TextColor } from '@lib/colors/colors'
import type { Url } from '@lib/routes'
import DynamicRoutes from '@lib/routes/DynamicRoutes'

export type DirectoryLink = {
  label: string
  url: Url
}

export const topTenOtherConditionDirectories: DirectoryLink[] = [
  { label: 'Tinnitus', url: DynamicRoutes.topic('for-tinnitus') },
  {
    label: 'Retinitis Pigmentosa',
    url: DynamicRoutes.topic('for-retinitis-pigmentosa'),
  },
  {
    label: 'Spinal Cord Injury',
    url: DynamicRoutes.topic('for-spinal-cord-injury'),
  },
  {
    label: 'Essential Tremor',
    url: DynamicRoutes.topic('for-essential-tremor'),
  },
  { label: 'Obesity', url: DynamicRoutes.topic('for-obesity') },
  { label: 'Vitiligo', url: DynamicRoutes.topic('for-vitiligo') },
  { label: 'Lyme Disease', url: DynamicRoutes.topic('for-lyme-disease') },
  { label: 'Lung Cancer', url: DynamicRoutes.topic('for-lung-cancer') },
  { label: 'Breast Cancer', url: DynamicRoutes.topic('for-breast-cancer') },
  {
    label: 'Pancreatic Cancer',
    url: DynamicRoutes.topic('for-pancreatic-cancer'),
  },
]

export const topTenPsychologyConditionDirectories: DirectoryLink[] = [
  { label: 'Depression', url: DynamicRoutes.topic('for-depression') },
  { label: 'Schizophrenia', url: DynamicRoutes.topic('for-schizophrenia') },
  { label: 'Anxiety', url: DynamicRoutes.topic('for-anxiety') },
  { label: 'PTSD', url: DynamicRoutes.topic('for-ptsd') },
  { label: 'ADHD', url: DynamicRoutes.topic('for-adhd') },
  { label: 'Autism', url: DynamicRoutes.topic('for-autism') },
  {
    label: 'Bipolar Disorder',
    url: DynamicRoutes.topic('for-bipolar-disorder'),
  },
  { label: 'Addiction', url: DynamicRoutes.topic('for-addiction') },
  { label: 'OCD', url: DynamicRoutes.topic('for-ocd') },
  {
    label: 'Eating Disorder',
    url: DynamicRoutes.topic('eating-disorder'),
  },
]

export const topTenTreatmentDirectories: DirectoryLink[] = [
  { label: 'Psilocybin', url: DynamicRoutes.topic('using-psilocybin') },
  { label: 'IVF', url: DynamicRoutes.topic('for-ivf') },
  { label: 'Dental Implant', url: DynamicRoutes.topic('for-dental-implant') },
  { label: 'Weight Loss', url: DynamicRoutes.topic('for-weight-loss') },
  { label: 'Smoking', url: DynamicRoutes.topic('for-smoking') },
  {
    label: 'Platelet-Rich Plasma',
    url: DynamicRoutes.topic('using-platelet-rich-plasma'),
  },
  { label: 'Testosterone', url: DynamicRoutes.topic('using-testosterone') },
  { label: 'Saxenda', url: DynamicRoutes.topic('using-saxenda') },
  { label: 'Melatonin', url: DynamicRoutes.topic('using-melatonin') },
  { label: 'Entresto', url: DynamicRoutes.topic('using-entresto') },
]

export const topTrialByCity: DirectoryLink[] = [
  {
    label: 'Saint Louis',
    url: DynamicRoutes.searchLanderMigration('near-saint-louis-mo'),
  },
  {
    label: 'Columbus',
    url: DynamicRoutes.searchLanderMigration('near-columbus-oh'),
  },
  {
    label: 'Portland',
    url: DynamicRoutes.searchLanderMigration('near-portland-or'),
  },
  {
    label: 'Ann Arbor',
    url: DynamicRoutes.searchLanderMigration('near-ann-arbor-mi'),
  },
  {
    label: 'Aurora',
    url: DynamicRoutes.searchLanderMigration('near-aurora-co'),
  },
  {
    label: 'Salt Lake City',
    url: DynamicRoutes.searchLanderMigration('near-salt-lake-city-ut'),
  },
  {
    label: 'Rochester',
    url: DynamicRoutes.searchLanderMigration('near-rochester-mn'),
  },
  {
    label: 'Birmingham',
    url: DynamicRoutes.searchLanderMigration('near-birmingham-al'),
  },
  {
    label: 'Detroit',
    url: DynamicRoutes.searchLanderMigration('near-detroit-mi'),
  },
  {
    label: 'New Haven',
    url: DynamicRoutes.searchLanderMigration('near-new-haven-ct'),
  },
]

export const topTenStateDirectories: DirectoryLink[] = [
  { label: 'Florida', url: DynamicRoutes.topic('within-florida') },
  { label: 'New Jersey', url: DynamicRoutes.topic('within-new-jersey') },
  {
    label: 'North Carolina',
    url: DynamicRoutes.topic('within-north-carolina'),
  },
  { label: 'Texas', url: DynamicRoutes.topic('within-texas') },
  { label: 'Ohio', url: DynamicRoutes.topic('within-ohio') },
  { label: 'California', url: DynamicRoutes.topic('within-california') },
  { label: 'Pennsylvania', url: DynamicRoutes.topic('within-pennsylvania') },
  { label: 'Kentucky', url: DynamicRoutes.topic('within-kentucky') },
  { label: 'New York', url: DynamicRoutes.topic('within-new-york') },
  { label: 'Indiana', url: DynamicRoutes.topic('within-indiana') },
]

type DirectoryLinksProps = {
  className?: string
  directories: DirectoryLink[]
  itemClassName?: string
  onClickLink?: () => void
  textColor?: TextColor
  useStack?: boolean
}

export const DirectoryLinks = ({
  className,
  directories,
  itemClassName,
  onClickLink,
  textColor = 'text-black',
  useStack = false,
}: DirectoryLinksProps) => {
  const links = directories.map((directory) => (
    <div key={directory.url.toString()} onClick={onClickLink}>
      <Link className={className} href={directory.url}>
        <Text
          className={classMerge(itemClassName, textColor, 'text-left')}
          styleName='p'
          value={directory.label}
        />
      </Link>
    </div>
  ))
  return useStack ? (
    <div className='grid grid-cols-1 gap-2'>{links}</div>
  ) : (
    <>{links}</>
  )
}

DirectoryLinks.Stack = Object.assign(
  (directoryLinksProps: DirectoryLinksProps) => (
    <DirectoryLinks {...directoryLinksProps} useStack={true} />
  ),
  { displayName: 'DirectoryLinks.Stack' },
)
