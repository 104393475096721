'use client'

import { getColorConfigFromColorGroup } from '@components/app/Button/helpers/getColorConfigFromColorGroup'
import UnreadMessagesBubble from '@components/app/NavBar/PatientBurgerMenu/UnreadMessagesBubble'
import { PatientInboxContext } from '@components/hooks/PatientInboxProvider'
import { useSignedInUser } from '@components/hooks/useSignedInUser'
import { useUserRoles } from '@components/hooks/useUserRoles'
import { Icon } from '@components/primitives/Icon'
import ModalOverlay from '@components/primitives/Modal/ModalOverlay'
import { classMerge } from '@components/utilities/classMerge'
import {
  type IconDefinition,
  faMicroscope,
} from '@fortawesome/pro-regular-svg-icons'
import { faBars } from '@fortawesome/pro-regular-svg-icons/faBars'
import { faGlasses } from '@fortawesome/pro-regular-svg-icons/faGlasses'
import { faIdCard } from '@fortawesome/pro-regular-svg-icons/faIdCard'
import { faInbox } from '@fortawesome/pro-regular-svg-icons/faInbox'
import { faRightFromBracket } from '@fortawesome/pro-regular-svg-icons/faRightFromBracket'
import { getUnreadMessageCount } from '@lib/api/users/inbox/unread-message-count'
import type { ColorGroup } from '@lib/colors/colors'
import Routes from '@lib/routes'
import PatientRoutes from '@lib/routes/PatientRoutes'
import TrialProRoutes from '@lib/routes/TrialProRoutes'
import { trackEvent } from '@lib/tracking'
import { TrackingEvents } from '@lib/tracking/TrackingEvents'
import { TrackingSources } from '@lib/tracking/TrackingSources'
import signOutUser from '@lib/utilities/signOutUser'
import { usePathname, useRouter } from 'next/navigation'
import { useContext, useEffect, useState } from 'react'

type MenuItem = {
  icon: IconDefinition
  label: string
  onClick?: () => void
  route?: string
}

export const PatientBurgerMenu = ({
  colorGroup,
}: {
  colorGroup: ColorGroup
}) => {
  const colorConfig = getColorConfigFromColorGroup(colorGroup)
  const user = useSignedInUser()
  const firstInitial = user?.email[0]?.toUpperCase() ?? '?' // TODO figure out how to get the first initial of the user's name
  let currentPath = usePathname()!
  const router = useRouter()
  const { isTrialPro } = useUserRoles()
  const [isOpen, setIsOpen] = useState(false)
  const handleToggle = () => setIsOpen((prevIsOpen) => !prevIsOpen)
  const [unreadMessageCount, setUnreadMessageCount] = useState(0)
  const patientInboxContext = useContext(PatientInboxContext)
  const fetchUnreadMessageCount = async () => {
    const result = await getUnreadMessageCount()
    if (result.success && result.data !== undefined) {
      setUnreadMessageCount(result.data)
    }
  }

  // If we are in the inbox, update the unread message count based on provider value
  useEffect(() => {
    if (patientInboxContext?.totalUnreadMessageCount !== undefined) {
      setUnreadMessageCount(patientInboxContext.totalUnreadMessageCount)
    }
  }, [patientInboxContext?.totalUnreadMessageCount])

  // On load fetch the unread message count
  useEffect(() => {
    fetchUnreadMessageCount()
  }, [])

  const handlesSignOut = () => {
    signOutUser()

    // Refresh to force navbar state to update to signed-out state
    if (currentPath === Routes.home) {
      router.refresh()
    } else {
      router.push(Routes.home)
    }
  }

  const MenuItems: MenuItem[] = [
    {
      icon: faIdCard,
      label: 'Profile',
      route: PatientRoutes.profile,
    },
    {
      icon: faMicroscope,
      label: 'Past Applications',
      route: PatientRoutes.pastApplications,
    },
    {
      icon: faInbox,
      label: 'Inbox',
      route: PatientRoutes.inbox, // FIXME: consider hiding this for unverified and 0 channel users
    },
    {
      icon: faRightFromBracket,
      label: 'Sign out',
      onClick: handlesSignOut,
    },
  ]

  if (isTrialPro) {
    MenuItems.unshift({
      icon: faGlasses,
      label: 'Site Staff Portal',
      route: TrialProRoutes.redirect,
    })
  }

  const handleClick = ({
    label,
    onClick,
  }: {
    label: string
    onClick?: () => void
  }) => {
    trackEvent(TrackingEvents.PATIENT_BURGER_MENU_ITEM_CLICKED, {
      source: TrackingSources.NAVBAR,
      val: label,
    })
    if (onClick) {
      onClick()
    }
  }

  return (
    <>
      <span
        className={classMerge(
          `relative flex cursor-pointer items-center justify-center rounded-full border-2 border-neutral100 ${colorConfig.backgroundColor}`,
        )}
        onClick={handleToggle}
      >
        <span
          className={classMerge(
            `relative flex h-[46px] w-[88px] items-center justify-center`,
          )}
        >
          <span
            className={classMerge(
              'mr-2 flex size-8 select-none items-center justify-center rounded-full bg-primary200/[0.2] text-primary900',
            )}
          >
            {firstInitial}
          </span>
          <Icon className={'text-black'} icon={faBars} />
          <UnreadMessagesBubble
            className='absolute left-10 top-0.5'
            count={unreadMessageCount}
          />
        </span>
        <span
          className={classMerge(
            `absolute right-0 top-14 z-10 flex min-w-[300px] flex-col rounded-xl border border-neutral100 bg-white shadow-md`,
            {
              hidden: !isOpen,
            },
          )}
        >
          {MenuItems.map((item, index) => (
            <a
              className={classMerge(
                'flex cursor-pointer items-center text-nowrap px-4 py-2 font-semibold text-neutral600 hover:bg-neutral50',
                item.route === currentPath
                  ? 'bg-neutral200 text-black hover:bg-neutral200'
                  : 'bg-white',
                index === 0 && 'rounded-t-xl pt-3',
                index === MenuItems.length - 1 &&
                  'mt-6 rounded-b-xl border-t border-neutral300 py-4',
              )}
              href={item.route}
              key={item.label}
              onClick={() =>
                handleClick({ label: item.label, onClick: item.onClick })
              }
            >
              <Icon className='mr-2' icon={item.icon} />
              {item.label}
              {item.label === 'Inbox' && (
                <UnreadMessagesBubble
                  className='ml-1'
                  count={unreadMessageCount}
                />
              )}
            </a>
          ))}
        </span>
      </span>
      <ModalOverlay
        className='z-auto'
        hidden={!isOpen}
        onClick={handleToggle}
      />
    </>
  )
}
