import { CloudflareImage } from '@components/primitives/Image/Cloudflare'
import Link from '@components/primitives/Link'
import { classMerge } from '@components/utilities/classMerge'
import type { ColorHexCode } from '@lib/colors/colors'
import Routes from '@lib/routes'
import PowerLogo from '../PowerLogo'

export const NavBarLogo = ({
  className,
  color = 'black',
  disableLink = false,
  logoImageId,
}: {
  className?: string
  color?: ColorHexCode
  disableLink?: boolean
  logoImageId?: string
}) => {
  if (logoImageId) {
    return (
      <div
        className={classMerge(
          'flex h-[70px] items-center justify-center pt-2 blur-none backdrop-blur-none',
          className,
        )}
      >
        <CloudflareImage
          alt='Logo'
          fit='contain'
          height={70}
          imageId={logoImageId}
          scaling={2}
          width={150}
        />
      </div>
    )
  }
  const styledLogo = (
    <div
      className={classMerge(
        'flex h-[70px] items-center justify-center pt-2 blur-none backdrop-blur-none',
        className,
      )}
    >
      <PowerLogo iconColor={color} nameColor={color} size='Large' />
    </div>
  )

  if (disableLink) {
    return styledLogo
  }

  return (
    <Link
      ariaLabel='Power logo'
      className={classMerge(
        'cursor-pointer blur-none backdrop-blur-none hover:opacity-50',
        className,
      )}
      href={Routes.home}
    >
      {styledLogo}
    </Link>
  )
}
