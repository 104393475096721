import { ChannelQueryParam } from '@components/hooks/PatientInboxProvider/consts'
import type { ConversationDetails } from '@components/hooks/PatientInboxProvider/types'
import urlWithQueryParams from '@lib/routes/helpers/urlWithQueryParams'
import PatientRoutes from '@lib/routes/PatientRoutes'
import { trackEvent } from '@lib/tracking'
import { TrackingEvents } from '@lib/tracking/TrackingEvents'
import { TrackingSources } from '@lib/tracking/TrackingSources'
import type { Role } from '@prisma/client'
import type {
  GroupChannel,
  UnreadMessageCount,
} from '@sendbird/chat/groupChannel'
import {
  sendbirdSelectors,
  useSendbirdStateContext,
} from '@sendbird/uikit-react'
import UserEventHandler from '@sendbird/uikit-react/handlers/UserEventHandler'
import { useRouter, useSearchParams } from 'next/navigation'
import {
  type ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'

export const PatientInboxContext = createContext<
  | {
      currentViewerRoles: Role[]
      detailsPanelHidden: boolean
      getChannelApplicationData: (
        channelUrl: string,
      ) => ConversationDetails | undefined
      getFilteredChannelList: () => string[]
      handleChannelSelected: (channel: GroupChannel | null) => void
      handleDetailsPanelToggled: (closed: boolean) => void
      selectedChannel: GroupChannel | null
      selectedChannelApplicationData: ConversationDetails | undefined
      totalUnreadMessageCount: number | undefined
    }
  | undefined
>(undefined)

function PatientInboxProvider({
  children,
  conversationDetails,
  currentViewerRoles,
}: {
  children: ReactNode
  conversationDetails: ConversationDetails[]
  currentViewerRoles: Role[]
}) {
  const router = useRouter()
  const searchParams = useSearchParams()
  const globalStore = useSendbirdStateContext()
  const sdkInstance = sendbirdSelectors.getSdk(globalStore)

  const [detailsPanelHidden, setDetailsPanelHidden] = useState(true)
  const [selectedChannel, setSelectedChannel] = useState<GroupChannel | null>(
    null,
  )
  const [totalUnreadMessageCount, setTotalUnreadMessageCount] = useState<
    number | undefined
  >(undefined)

  useEffect(() => {
    if (sdkInstance && sdkInstance.addUserEventHandler) {
      const handlerId = Math.random().toString()
      sdkInstance.addUserEventHandler(
        handlerId,
        new UserEventHandler({
          onTotalUnreadMessageCountChanged: (count: UnreadMessageCount) => {
            setTotalUnreadMessageCount(count.groupChannelCount)
          },
        }),
      )
      return () => {
        sdkInstance.removeUserEventHandler(handlerId)
      }
    }
  }, [sdkInstance])

  useEffect(() => {
    const existingParams = Object.fromEntries(searchParams?.entries() ?? [])
    delete existingParams[ChannelQueryParam]
    const url = selectedChannel
      ? urlWithQueryParams(PatientRoutes.inbox, {
          ...existingParams,
          [ChannelQueryParam]: selectedChannel.url,
        })
      : urlWithQueryParams(PatientRoutes.inbox, existingParams)
    router.push(url)
  }, [selectedChannel])

  useEffect(() => {
    // Set active channel if one is provided in url params
    const channelUrlFromQueryParams = searchParams?.get(ChannelQueryParam)
    if (sdkInstance && sdkInstance.groupChannel && channelUrlFromQueryParams) {
      sdkInstance.groupChannel
        .getChannel(channelUrlFromQueryParams)
        .then((channel) => setSelectedChannel(channel))
        .catch(() => {
          // Ignore error
        })
    }
  }, [sdkInstance])

  const selectedChannelApplicationData = selectedChannel
    ? conversationDetails.find(
        (conversation) =>
          conversation.trialApplicationIdToken === selectedChannel.url,
      )
    : undefined

  function getChannelApplicationData(channelUrl: string) {
    return conversationDetails.find(
      (conversation) => conversation.trialApplicationIdToken === channelUrl,
    )
  }

  function getFilteredChannelList() {
    return conversationDetails.map(
      (conversation) => conversation.trialApplicationIdToken,
    )
  }

  function handleChannelSelected(channel: GroupChannel | null) {
    setSelectedChannel(channel)
    if (channel) {
      trackEvent(TrackingEvents.INBOX_CHANNEL_SELECTED, {
        source: TrackingSources.PATIENT_INBOX,
        val: channel.url,
      })
    }
  }

  function handleDetailsPanelToggled(closed: boolean) {
    setDetailsPanelHidden(closed)
    const event = closed
      ? TrackingEvents.INBOX_DETAILS_PANEL_CLOSED
      : TrackingEvents.INBOX_DETAILS_PANEL_OPENED
    trackEvent(event, { source: TrackingSources.PATIENT_INBOX })
  }

  return (
    <PatientInboxContext.Provider
      value={{
        currentViewerRoles,
        detailsPanelHidden,
        getChannelApplicationData,
        getFilteredChannelList,
        handleChannelSelected,
        handleDetailsPanelToggled,
        selectedChannel,
        selectedChannelApplicationData,
        totalUnreadMessageCount,
      }}
    >
      {children}
    </PatientInboxContext.Provider>
  )
}

export function usePatientInbox() {
  const context = useContext(PatientInboxContext)
  if (context === undefined) {
    throw new Error(
      'usePatientInbox must be used within a PatientInboxContext.Provider',
    )
  }
  return context
}

export default PatientInboxProvider
