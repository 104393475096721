import { classMerge } from '@components/utilities/classMerge'
import { MAX_UNREAD_MESSAGES_COUNT } from '@modules/patientTrialApplications/helpers/matchesTableDataSelectFragment'

export default function UnreadMessagesBubble({
  className,
  count,
}: {
  className?: string
  count?: number
}) {
  if (count === undefined || count < 1) {
    return null
  }
  return (
    <span
      className={classMerge(
        'flex size-5 items-center justify-center rounded-full border border-white bg-hope900 text-xs text-white',
        className,
      )}
    >
      {count >= MAX_UNREAD_MESSAGES_COUNT
        ? `${MAX_UNREAD_MESSAGES_COUNT}+`
        : count}
    </span>
  )
}
